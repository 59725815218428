import React from 'react'
import cn from 'classnames'
import { IApplication } from '~/pages/App/Manager/AppManager.interface'
import Link from '~/components/Link'
import routes from '~/routes'

import styles from '../AppManager.scss'

const NameColumn = ({ id, image, name }: IApplication) => {
  return (
    // eslint-disable-next-line
    <Link
      route={routes.appDashboard}
      params={{ appId: id }}
      className={cn('d-flex align-items-center ps-3', styles.linkToApp)}
      color="">
      <span className={styles.appImageWrapper}>
        <span
          style={{ backgroundImage: `url(${image})` }}
          className={styles.appImage}
        />
      </span>
      <span className="ps-3 text-truncate">{name}</span>
    </Link>
  )
}

export default NameColumn
