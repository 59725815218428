import React, { useEffect, useState } from 'react'
import { Card, Container } from 'reactstrap'
import {
  ICampaignsListItem,
  QUERY_PARAMS_DEFAULTS
} from './CampaignsList.interface'
import { getGroups } from '~/api/groups'
import { useStore, withStore } from '~/dataStore'
import TableNew from '~/components/Table/TableNew'
import tableColumns from './components/tableColumns'
import routes from '~/routes'
import useQueryParams from '~/hooks/useQueryParams'
import Toolbar from './components/Toolbar'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import CreateGroupModal from '~/components/Table/components/CreateGroupModal'
import EditGroupModal from '~/components/Table/components/EditGroupModal'
import DeleteGroup from '~/components/Table/components/DeleteGroup'
import AddElementsToGroupModal from '~/components/Table/components/AddElementsToGroup'
import RemoveElementsFromGroupModal from '~/components/Table/components/RemoveElementsFromGroup'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import useCampaigns, { useInvalidateCampaigns } from './useCampaigns'
import useSelectListItems from '~/hooks/useSelectListItems'
import TableHeader from '~/components/Table/TableHeader'
import DeleteElementsModal from '~/components/modals/DeleteElementsModal'
import HideFromFeedModal from './components/HIdeFromFeedModal'

import './CampaignsList.scss'

const CampaignsList = () => {
  const { selectListItems, selectedListItems, clearSelectedItems } =
    useSelectListItems<ICampaignsListItem>()

  const {
    app: {
      currentApp: { id: appId }
    },
    groups: {
      setGroups,
      groups,
      createGroup,
      isPerforming,
      editGroup,
      removeGroup,
      removeElementsFromGroup,
      addElementsToGroup
    }
  } = useStore()
  const paginationQuery = usePaginationQuery({ perPage: 50 })
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>()
  const invalidateCampaigns = useInvalidateCampaigns()
  const queryParams = useQueryParams(QUERY_PARAMS_DEFAULTS)

  const {
    data: campaigns,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    isLoading
  } = useCampaigns({
    ...queryParams,
    ...paginationQuery.getPaginationQuery()
  })
  const bulkActions = campaigns?.bulkActions

  useEffect(() => {
    getGroups(appId, 'campaigns').then((response) => setGroups(response.data))

    return () => {
      setGroups([])
    }
  }, [appId])

  return (
    <>
      <Container fluid>
        <TableHeader text="New Campaign" route={routes.newCampaign}>
          <>
            Campaigns{' '}
            <span className="text-muted">
              ({campaigns?.metadata.dataCount})
            </span>
          </>
        </TableHeader>
        <Card style={{ marginBottom: 350 }}>
          <Toolbar
            queryParams={queryParams}
            handleSearch={paginationQuery.handleSearch}
            searchQuery={paginationQuery.searchQuery}
            setSelectedCampaigns={clearSelectedItems}
            selectedCampaigns={selectedListItems}
            batchActionUrls={bulkActions}
            setSelectedGroup={setSelectedGroup}
            isLoading={isRefetching}
          />
          <div className="p-3">
            <TableNew
              onUpdateRow={invalidateCampaigns}
              isLoading={isLoading}
              isLoadingMore={isFetchingNextPage}
              columns={tableColumns}
              selectedRows={selectedListItems}
              onSelect={selectListItems}
              hasMore={hasNextPage}
              onLoadMore={() => fetchNextPage()}
              rows={campaigns?.pages || []}
              sortOrder={paginationQuery.order}
              sortColumn={paginationQuery.sortField}
              onSort={paginationQuery.handleSort}
            />
          </div>
        </Card>
      </Container>
      <DeleteElementsModal elementType="Campaign" nameKey="name">
        Deleting action may take up to 15 min
      </DeleteElementsModal>
      <CreateGroupModal
        groupType="campaigns"
        onSubmit={createGroup}
        isSaving={isPerforming}
      />
      <EditGroupModal
        group={selectedGroup}
        isSaving={isPerforming}
        onSubmit={editGroup}
      />
      <DeleteGroup
        onSubmit={(groupID) => {
          removeGroup(groupID)
          invalidateCampaigns()
          clearSelectedItems()
        }}
        queryParams={queryParams}
        group={selectedGroup}
        isSaving={isPerforming}
      />

      <AddElementsToGroupModal
        groups={groups}
        onSubmit={(groupId: string) =>
          addElementsToGroup({
            url: bulkActions.addToGroup,
            groupId,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateCampaigns()
              clearSelectedItems()
            }
          })
        }
      />

      <RemoveElementsFromGroupModal
        onSubmit={(selectedGroups) => {
          if (!bulkActions) return
          removeElementsFromGroup({
            selectedGroups,
            url: bulkActions?.removeFromGroup,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateCampaigns()
              clearSelectedItems()
            }
          })
        }}
        nameKey="name"
        elements={Array.from(selectedListItems, ([, value]) => value)}
        elementsName="Campaigns"
        isSaving={isPerforming}
      />
      <HideFromFeedModal />
    </>
  )
}

export default withStore(CampaignsList)
