import { toastr } from 'react-redux-toastr'

export enum NotificationType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export function clearNotifications(type: string) {
  toastr.removeByType(type)
}

export function showNotification(
  message: string | React.ReactElement,
  type: NotificationType
): void {
  let stringMessage = ''
  const options: any = {
    showCloseButton: true,
    progressBar: true,
    position: 'top-right',
    title: ''
  }

  let toastrInstance
  switch (type) {
    case NotificationType.INFO:
      toastrInstance = toastr.info
      break
    case NotificationType.WARNING:
      toastrInstance = toastr.warning
      break
    case NotificationType.ERROR:
      options.timeOut = 0
      options.extendedTimeOut = 0
      options.disableTimeOut = true
      toastrInstance = toastr.error
      break
    default:
      toastrInstance = toastr.success
  }

  if (typeof message !== 'string') {
    options.component = message
  } else {
    stringMessage = message
  }

  toastrInstance(stringMessage, options)
}
