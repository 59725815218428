import { ISegmentUserRQ } from '~/dataStore/Segment.interface'
import {
  ISegmentsDTO,
  ISegmentsListQueryParams
} from '~/pages/Segment/SegmentsList/SegmentsList.interface'
import { get, post, upload } from './v2'
import { post as postV1, put as putV1 } from './v1'
import { getRequest } from '~/api/requests'
import { ID } from '~/common.interface'
import {
  RuleWithCategoriesDTO,
  SegmentDTO
} from '~/pages/Segment/SegmentsBuilder/SegmentBuilder.interface'

export function fetchSegments<T = ISegmentsDTO>(
  appId: ID,
  params?: ISegmentsListQueryParams,
  abortSignal?: AbortSignal
): Promise<T> {
  return get(`${appId}/segments`, params, abortSignal)
}

export function fetchSegmentDetails(
  appId: ID,
  segmentId: ID
): Promise<SegmentDTO> {
  return get(`${appId}/segments/${segmentId}`)
}

export function fetchSegmentsReach(
  appId: ID,
  ids: string[]
): Promise<{
  min: number
  max: number
}> {
  const query: { segmentIds: string[] } = {
    segmentIds: ids
  }

  return get(`${appId}/segments/estimate`, query)
}

export function getSegmentUserList(
  appId: string,
  segmentId: string,
  query?: {
    page?: number
    perPage?: number
    searchQuery?: string
    sort?: string
    order?: 'asc' | 'desc'
  }
): Promise<ISegmentUserRQ> {
  return get(`${appId}/segments/${segmentId}/users`, query)
}

export function exportSegmentUsers(
  appId: string,
  segmentId: string
): Promise<void> {
  return getRequest(`/mobile/apps/${appId}/segments/${segmentId}/export_users`)
}

export function fetchSegmentRules(appId: ID): Promise<RuleWithCategoriesDTO> {
  return get(`${appId}/segment_rules`)
}

export function saveSegment(appId: ID, data: SegmentDTO): Promise<SegmentDTO> {
  return postV1(`${appId}/segments`, data)
}

export function updateSegment(
  appId: ID,
  segmentId: ID,
  data: SegmentDTO
): Promise<SegmentDTO> {
  return putV1(`${appId}/segments/${segmentId}`, data)
}

export function estimateSegment(
  appId: ID,
  data: { segment: Pick<SegmentDTO, 'groups'> }
): Promise<{
  totalUsersCount: number
  usersCount: number
}> {
  return post(`${appId}/segments/users_count`, data)
}

export function fetchTotalAudience(appId: ID): Promise<{
  totalAudience: number
}> {
  return get(`${appId}/segments/total_audience`)
}

export function duplicateSegment(
  appId: ID,
  segmentId: ID
): Promise<SegmentDTO> {
  return postV1(`${appId}/segments/${segmentId}/duplicate`)
}

export function createSegmentFromCSVFile(
  appId: ID,
  formData: FormData
): Promise<SegmentDTO & { link: string }> {
  return upload(`${appId}/segments/create_from_file`, formData)
}
