import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import useCampaignsDashboardStore from './CampaignDashboard.context'
import CampaignSummary from './components/CampaignSummary'
import LearnMoreBox from './components/LearnMoreWidget'
import DeliveryVsOpenVsClickChart from './components/DeliveryVsOpenVsClick'
import TrafficByTheWeekDay from './components/TrafficByTheWeekDay'
import EngagementByTheHour from './components/EngagmentByTheHour'
import PrimarySecondaryConversion from './components/PrimarySecondaryConversion'
import PrimaryVsSecondaryGoal from './components/PrimaryVsSecondaryGoal'
import CampaignsDashboardCampaignsList from './components/CampaignsDashboardCampaignsList'
import MultiSelect from '~/components/forms/MultiSelect'
import StatusOption from '~/components/Filters/StatusOption'
import CampaignsAndGroupsMultiSelect from '~/pages/Campaign/CampaignsDashboard/components/CampaignsAndGroupsMultiSelect'
import CampaignsSpecificMetrics from './components/CampaignsSpecificMetrics'
import useQueryParams from '~/hooks/useQueryParams'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import { FilterAction } from './Store/CampaignsDashboard.interface'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'
import useAbortController from '~/hooks/useAbortController'

interface IHandleFilterProps {
  checked?: boolean
  value?: string
  reset?: boolean
}

const campaignStatusOptions = [
  {
    name: (
      <StatusOption
        name={CampaignStatus.DELIVERED}
        status={CampaignStatus.DELIVERED}
      />
    ),
    value: 'delivered',
    disabled: true
  },
  {
    name: (
      <StatusOption
        name={CampaignStatus.ACTIVE}
        status={CampaignStatus.ACTIVE}
      />
    ),
    value: 'true'
  }
]

enum ABORT_CONTROLLERS {
  REPORT = 'fetchReportAbortController',
  FILTER_RESOURCES = 'fetchFilterResourcesAbortController',
  CAMPAIGNS = 'fetchCampaignsAbortController'
}

function CampaignsDashboard(): React.ReactElement | null {
  const {
    report,
    campaigns,
    campaignsMetadata,
    campaignsFetching,
    statsByHour,
    filterResources,
    fetchReport,
    fetchCampaigns,
    summaryRates,
    statsByWeekDay,
    summaryTotalNumbers,
    fetchFilterResources,
    dailyEventsRateStats,
    summaryGoalsRates,
    dailyGoalsPerformanceStats,
    eventsRateStatsByCampaign
  } = useCampaignsDashboardStore()
  const [filterAction, setFilterAction] = useState<
    FilterAction.CAMPAIGN_CHARTS | FilterAction.CAMPAIGN_LIST
  >()

  const abortControllers = useAbortController()

  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      },
      currentApp
    }
  } = useStore()

  const appId = currentApp.id

  const queryParams = useQueryParams({
    ongoing: '',
    groupIds: '',
    campaignIds: ''
  })

  const {
    groupIds,
    campaignIds,
    ongoing
  }: {
    groupIds: string
    ongoing: string
    campaignIds: string
  } = queryParams

  const {
    page,
    sortField,
    order,
    getPaginationQuery,
    setSortField,
    setPage,
    setOrder,
    handleLoadMore,
    handleSort
  } = usePaginationQuery({ perPage: 8 })

  const resetCampaignsSortAndPagination = () => {
    setSortField('')
    setPage(1)
    setOrder('desc')
  }

  const resetResourceFilter = () => {
    queryParams.campaignIds = ''
    queryParams.groupIds = ''
  }

  const handleFilterIds = (
    currentQueryParams: string,
    checked?: boolean,
    newValue?: string
  ): string => {
    const values = currentQueryParams.split(',').filter(String)
    if (checked && newValue) {
      return values.concat(newValue).join(',')
    }
    return values.filter((option) => option !== newValue).join(',')
  }

  const handleFilterGroups = ({
    checked,
    value,
    reset
  }: IHandleFilterProps) => {
    queryParams.campaignIds = ''
    queryParams.groupIds = !reset
      ? handleFilterIds(queryParams.groupIds, checked, value)
      : ''
    resetCampaignsSortAndPagination()
    setFilterAction(FilterAction.CAMPAIGN_CHARTS)
  }

  const handleFilterCampaigns = ({
    checked,
    value,
    reset
  }: IHandleFilterProps) => {
    queryParams.groupIds = ''
    queryParams.campaignIds = !reset
      ? handleFilterIds(queryParams.campaignIds, checked, value)
      : ''
    resetCampaignsSortAndPagination()
    setFilterAction(FilterAction.CAMPAIGN_CHARTS)
  }

  const handleFilterStatus = (value: string[]) => {
    queryParams.ongoing = value[0] || ''
    resetCampaignsSortAndPagination()
    resetResourceFilter()
    setFilterAction(FilterAction.CAMPAIGN_CHARTS)
  }

  const onLoadMore = () => {
    setFilterAction(FilterAction.CAMPAIGN_LIST)
    handleLoadMore()
  }

  const onSort = (value: string) => {
    handleSort(value)
    setFilterAction(FilterAction.CAMPAIGN_LIST)
  }

  useEffect(() => {
    abortControllers.abort(ABORT_CONTROLLERS.REPORT)
    fetchReport(
      appId,
      { groupIds, campaignIds, ongoing },
      abortControllers.getAbortController(ABORT_CONTROLLERS.REPORT).signal
    )
  }, [groupIds, campaignIds, ongoing])

  useEffect(() => {
    abortControllers.abort(ABORT_CONTROLLERS.CAMPAIGNS)
    fetchCampaigns(
      appId,
      {
        ...getPaginationQuery(),
        groupIds,
        campaignIds,
        ongoing
      },
      filterAction,
      abortControllers.getAbortController(ABORT_CONTROLLERS.CAMPAIGNS).signal
    )
  }, [groupIds, campaignIds, ongoing, page, order, sortField])

  useEffect(() => {
    abortControllers.abort(ABORT_CONTROLLERS.FILTER_RESOURCES)
    fetchFilterResources(
      appId,
      { ongoing },
      abortControllers.getAbortController(ABORT_CONTROLLERS.FILTER_RESOURCES)
        .signal
    )
  }, [ongoing])

  return (
    <Container fluid className="pb-5">
      <div className="d-flex justify-content-between">
        <Header className="mb-3">
          <HeaderTitle>
            Email Stats <span className="text-muted">(UTC)</span>
          </HeaderTitle>
        </Header>
        <div>
          <MultiSelect
            title="Status"
            selectedOptions={[queryParams.ongoing].filter(Boolean)}
            className="bg-white me-3"
            onSelect={handleFilterStatus}
            resetOptionName="Show All"
            options={campaignStatusOptions}
          />
          <CampaignsAndGroupsMultiSelect
            filterResources={filterResources}
            handleFilterCampaigns={handleFilterCampaigns}
            handleFilterGroups={handleFilterGroups}
            selectedCampaigns={campaignIds}
            selectedGroups={groupIds}
          />
        </div>
      </div>
      <CampaignSummary
        delivered={report?.delivery?.total}
        sent={report?.sent?.total}
        summaryRates={summaryRates}
        summaryNumbers={summaryTotalNumbers}
      />
      <Row className="mb-4">
        <Col xs={8} xxxl={9} widths={['xs', 'xxxl']}>
          <DeliveryVsOpenVsClickChart chartData={dailyEventsRateStats} />
        </Col>
        <Col xs={4} xxxl={3} widths={['xs', 'xxxl']}>
          <LearnMoreBox />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col
          xs={12}
          xxxl={6}
          widths={['xs', 'xxxl']}
          className="mb-4 mb-xxxl-0">
          <TrafficByTheWeekDay chartData={statsByWeekDay} />
        </Col>
        <Col xs={12} xxxl={6} widths={['xs', 'xxxl']}>
          <EngagementByTheHour chartData={statsByHour} />
        </Col>
      </Row>
      {campaignsGoals && (
        <Row className="mb-4">
          <PrimarySecondaryConversion chartData={dailyGoalsPerformanceStats} />
          <Col xs={12} xxxl={6} widths={['xs', 'xxxl']}>
            <PrimaryVsSecondaryGoal chartData={summaryGoalsRates} />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <CampaignsSpecificMetrics chartData={eventsRateStatsByCampaign} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CampaignsDashboardCampaignsList
            campaigns={campaigns}
            metadata={campaignsMetadata}
            campaignsFetching={campaignsFetching}
            onLoadMore={onLoadMore}
            onSort={onSort}
            page={page}
            order={order}
            sortField={sortField}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default withStore(CampaignsDashboard)
