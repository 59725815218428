import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { uniqueId } from 'lodash'
import cn from 'classnames'
import { withStore } from '~/dataStore'
import './uploadImage.scss'
import UploadMediaPreview from './UploadMediaPreview'
import { FileMimeType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  image?: string | null
  fileName: string | undefined
  small?: boolean
  onChange: (
    objectURL: string,
    fileName: string,
    file: File | null,
    valid: boolean
  ) => void
  invalid?: boolean
  richMedia?: boolean
  withLabel?: boolean
  allowedTypes: string[]
  fileType?: FileMimeType
  maxFileSizes: { type: FileMimeType; maxSize: number; error: string }[]
  rounded?: boolean
  className?: string
}

function UploadImage({
  onChange,
  image,
  fileName,
  small,
  invalid,
  richMedia = false,
  withLabel = true,
  allowedTypes,
  fileType = 'image',
  maxFileSizes,
  rounded,
  className
}: IProps): ReactElement {
  const [error, setError] = useState<string | null>(null)
  const [withCrop, setWithCrop] = useState(true)
  const inputFileKey = useRef(uniqueId('inputFile'))

  function handleRemove(): void {
    if (image) {
      URL.revokeObjectURL(image)
    }
    onChange('', '', null, true)
    inputFileKey.current = uniqueId()
  }

  function getTypeValidator(
    file: File
  ): undefined | { type: FileMimeType; maxSize: number; error: string } {
    const currentFileType = file.type.split('/')[0]
    return maxFileSizes.find((item) => item.type === currentFileType)
  }

  function validateFile(file: File): boolean {
    const typeValidator = getTypeValidator(file)

    if (typeValidator && file.size > typeValidator.maxSize) {
      handleRemove()
      setError(`File size exceeded ${typeValidator.error}!`)
      return false
    }

    if (
      !file.name ||
      !allowedTypes.includes(file.name.split('.').pop() || '')
    ) {
      handleRemove()
      setError(
        `File must contain the valid extension: ${allowedTypes.join(', ')}`
      )
      return false
    }

    return true
  }

  function isGif(name: string): boolean {
    return !!name.split('.').pop()?.includes('gif')
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError(null)
    if (!e.target.files?.[0]) {
      return
    }

    if (!validateFile(e.target.files[0])) {
      onChange('', '', null, false)
      return
    }
    const file = URL.createObjectURL(e.target.files[0])
    onChange(file, e.target.files[0].name, e.target.files[0], true)
  }

  useEffect(() => {
    inputFileKey.current = uniqueId()
  }, [fileName])

  useEffect(() => {
    setWithCrop(!isGif(fileName || ''))
  }, [fileName])

  return (
    <div
      className={cn('position-relative upload-image-wrapper', className, {
        'upload-image-wrapper--rounded': rounded
      })}>
      {!image && (
        <div
          className={cn('position-relative upload-image', {
            'upload-image--rich-media': richMedia
          })}>
          <div
            className={cn(
              'upload-image__form position-relative d-flex flex-column justify-content-center align-items-center',
              { 'upload-image__form--small': small }
            )}>
            <input
              data-testid="file"
              key={inputFileKey.current}
              id="upload-file"
              className="upload-image__input"
              type="file"
              name="file"
              accept={allowedTypes.map((type) => `.${type}`).join(',')}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onChange={handleChange}
            />
            <svg
              className="upload-image__placeholder"
              width="48"
              height="38"
              viewBox="0 0 48 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M40 32V33.3333C40 35.5425 38.2092 37.3333 36 37.3333H4C1.79083 37.3333 0 35.5425 0 33.3333V12C0 9.79083 1.79083 8 4 8H5.33333V25.3333C5.33333 29.0093 8.324 32 12 32H40ZM48 25.3333V4C48 1.79083 46.2092 0 44 0H12C9.79083 0 8 1.79083 8 4V25.3333C8 27.5425 9.79083 29.3333 12 29.3333H44C46.2092 29.3333 48 27.5425 48 25.3333ZM21.3333 8C21.3333 10.2092 19.5425 12 17.3333 12C15.1242 12 13.3333 10.2092 13.3333 8C13.3333 5.79083 15.1242 4 17.3333 4C19.5425 4 21.3333 5.79083 21.3333 8ZM13.3333 20L17.9596 15.3738C18.3501 14.9833 18.9833 14.9833 19.3738 15.3738L22.6667 18.6667L33.9596 7.37375C34.3501 6.98325 34.9833 6.98325 35.3738 7.37375L42.6667 14.6667V24H13.3333V20Z"
                fill="#D6DFEB"
              />
            </svg>

            {withLabel && (
              <>
                <div
                  data-testid="invalid"
                  className={cn(
                    'upload-image__text',
                    { 'error-label': invalid },
                    { 'text-center mt-2': small }
                  )}>
                  <>Upload {small && <br />} photo</>
                </div>
                {!small && (
                  <div className="upload-image__text">(PNG, JPEG or GIF)</div>
                )}
              </>
            )}
            {error && (
              <div
                data-testid="error"
                className={cn(
                  'error-label position-absolute upload-image__error text-center',
                  { 'upload-image__error--small': small }
                )}>
                {error}
              </div>
            )}
          </div>
        </div>
      )}

      {image && (
        <UploadMediaPreview
          small={small}
          mediaFile={image}
          mediaType={fileType}
          fileName={fileName || ''}
          withCrop={withCrop}
          onChange={onChange}
        />
      )}
    </div>
  )
}

export default withStore(UploadImage)
