import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import PreviewMobile from '~/pages/Campaign/Notification/Preview/PreviewMobile'
import {
  CampaignType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import PreviewDesktop from './PreviewDesktop'

interface IProps {
  target: string
  deviceType: DeviceType
  size?: 'sm' | 'lg' | 'xl'
  parts?: IPreviewParts
  campaignType?: CampaignType
}

export default function PreviewPopover({
  target,
  campaignType,
  size = 'lg',
  deviceType,
  parts
}: IProps): React.ReactElement {
  return (
    <UncontrolledPopover
      // boundariesElement="window"
      hideArrow
      trigger="hover"
      delay={150}
      popperClassName="preview-device--zoom"
      target={target}>
      <PopoverBody>
        {parts && campaignType === CampaignType.EMAIL && (
          <PreviewDesktop
            size={size}
            srcDoc={'html' in parts ? parts.html : undefined}
            url={'imageUrl' in parts ? parts.imageUrl : undefined}
          />
        )}

        {parts && campaignType !== CampaignType.EMAIL && (
          <PreviewMobile
            device={deviceType}
            deviceSize={size}
            preview={parts}
            campaignType={campaignType}
          />
        )}
      </PopoverBody>
    </UncontrolledPopover>
  )
}
