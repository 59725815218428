import { ID } from '~/common.interface'

export const QUERY_PARAMS_DEFAULTS = {
  sort: 'created_at',
  order: 'desc',
  searchQuery: '',
  filterSegments: ''
}

export interface IUsersListQueryParams {
  page?: number
  perPage?: number
  searchQuery?: string
  filterSegments?: string
  sort?: string
  order?: 'asc' | 'desc'
}

export interface IUser {
  alias: string
  createdAt: string
  currentCity: string
  id: string
  lastName: string
  fullName: string
  lastSessionAt: string
  viewProfile: string
}

export interface IUsersDTO {
  data: IUser[]
  metadata: IUsersListMetadata
}

export interface IUsersListQueryData {
  data: {
    pages: Array<{
      data: IUser[]
      metadata: IUsersListMetadata
    }>
    pageParams: Array<number | undefined>
  }
}

export interface IUsersListMetadata {
  exportUsers: string
  page: number
  perPage: number
  segmentsList: IUsersListSegment[]
  totalPages: number
  totalUsers: number
  dataCount?: number
}

export interface IUsersListSegment {
  id: ID
  name: string
}

export interface IDevice {
  active: boolean
  appVersion: string
  guid: string
  language: string
  locationPermission: boolean
  osVersion: string
  pushPermission: boolean
  sdkVersion: string
  timezone: string
  type: string
  uninstalled: boolean
  deviceDataNotAvailable: boolean
}

export interface IUserSubscription {
  name: string
  type: string
  read: boolean
  readAt: string
  deliveredAt: string
}

export interface IUserBeaconEvent {
  name: string
  type: string
  occurredAt: string
}

export interface IUserGeofenceEvent {
  name: string
  type: string
  occurredAt: string
}

export interface IUserDTO {
  id: ID
  age: number
  alias: string
  createdAt: string
  currentCity: string
  currentCountry: string
  currentLocation: string | null
  customAttrs: Record<string, any>
  customData: Array<string>
  devices: IDevice[]
  email: string
  firstName: string
  fullName: string
  gender: 'male' | 'female'
  lastName: string
  locationTrackingEnabled: boolean
  note: string
  phone: string | null
  optOutPreferences: {
    push: boolean
    email: boolean
    sms: boolean
  }
  session: {
    firstAt: string | null
    lastAt: string | null
    createdCount: number
  }
  status: {
    active: boolean
    deleted: boolean
    uninstalled: boolean
    unsubscribed: boolean
  }
  updatedAt: string
}

export interface IUploadUsersDTO {
  updatedCount?: number
  createdCount?: number
  errors?: {
    link?: string
    rows?: Record<string, Array<string>>
    file?: Array<string>
  }
}
