import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { Button, Col, Row } from 'reactstrap'
import large from '~/components/icons/inapp/inapp_large_preview.svg'
import InAppLargeAnimatedIcon from './InAppLargeAnimatedIcon'
import small from '~/components/icons/inapp/inapp_small_preview.svg'
import InAppSmallAnimatedIcon from './InAppSmallAnimatedIcon'
import Link from '~/components/Link'
import { SingleRoute } from '~/dataStore/Store.interface'

function InAppSize({
  route,
  params,
  goBack
}: {
  route: SingleRoute
  params?: Record<string, unknown>
  goBack: () => void
}): ReactElement | null {
  if (!params) {
    return null
  }

  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <>
      <div>
        <Button
          onClick={goBack}
          color=""
          className="text-decoration-none ps-0 pt-0 align-self-start">
          <FontAwesomeIcon className="me-2" icon={faChevronLeft} />
          In-App Notification
        </Button>

        <Link
          data-testid="inapp-large"
          tabIndex={-1}
          route={route}
          params={{ ...params, size: 'large' }}
          className="d-block px-2 py-0 mt-0 mt-xxl-3 text-decoration-none campaign-subtile group">
          <Row className="align-items-center">
            <Col xs={4}>
              <div className="campaign-subtile__img d-flex justify-content-center">
                <img
                  src={large}
                  alt="full screen inapp"
                  className="group-hover:hidden"
                />
                <InAppLargeAnimatedIcon className="d-none group-hover:block" />
              </div>
            </Col>
            <Col xs={8}>
              <div>
                <div className="campaign-tile__name">Full-Screen</div>
                <p className="campaign-tile__description">
                  Appears the next time the user logs into the App
                </p>
              </div>
            </Col>
          </Row>
        </Link>
        <Link
          tabIndex={-1}
          route={route}
          params={{ ...params, size: 'small' }}
          className="d-block py-0 pb-3 px-2 text-decoration-none campaign-subtile group">
          <Row className="align-items-center">
            <Col xs={4}>
              <div className="campaign-subtile__img d-flex justify-content-center">
                <img
                  src={small}
                  alt="small inapp"
                  className="group-hover:hidden"
                />
                <InAppSmallAnimatedIcon className="d-none group-hover:block" />
              </div>
            </Col>
            <Col xs={8}>
              <div>
                <div className="campaign-tile__name">Small</div>
                <p className="campaign-tile__description">
                  This is a discreet way to notify users. Use this style for
                  minimum <br /> interruption.
                </p>
              </div>
            </Col>
          </Row>
        </Link>
      </div>
    </>
  )
}

export default InAppSize
