import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Container } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { withStore, useStore } from '~/dataStore'
import useQueryParams from '~/hooks/useQueryParams'
import { isoDate } from '~/utils/time'
import { getRequest } from '~/api/requests'
import colors from '~/utils/color'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import PieChart from '~/pages/DevicesDashboard/UI/PieChart'
import BarChart from '~/pages/DevicesDashboard/UI/BarChart'
import SelectDays from '~/pages/App/Dashboard/UI/SelectDays'
import DisabledFeatureCard from '~/components/reports/DisabledFeatureCard'

import { pieChart } from './style.scss'

const DevicesDashboard = () => {
  const [data, setData] = useState()
  const queryParams = useQueryParams({ days: 7 })

  const {
    app: {
      openCurtain,
      appDetails: { featureFlags }
    }
  } = useStore()

  const { appId } = useParams()

  useEffect(() => {
    const { days } = queryParams

    if (!days) return

    getRequest(`/mobile/apps/${appId}/dashboards.json`, {
      from: isoDate(moment().subtract(days, 'days')),
      to: isoDate()
    }).then((response) => {
      setData(response)
      openCurtain()
    })
  }, [appId, queryParams.days, openCurtain])

  // eslint-disable-next-line
  const sdkWarning =
    'Newest SDK version required for accurate figures. Please contact your app provider for more details.'

  return (
    <>
      <Container fluid>
        <Header className="d-flex justify-content-between align-items-end mt-0 pt-3 mb-4">
          <HeaderTitle className="mb-0">
            Device Stats
            <div
              className="fw-bold mt-2"
              style={{
                color: colors.blueGray,
                fontStyle: 'italic',
                fontSize: 12
              }}>
              {sdkWarning}
            </div>
          </HeaderTitle>
          <SelectDays
            queryParams={queryParams}
            className="d-flex justify-content-end align-items-center"
          />
        </Header>
        {data && (
          <div className="pt-3">
            <Row>
              <Col md="4" className="d-flex">
                <PieChart
                  title="Push permission"
                  labels={['Yes', 'No']}
                  data={[
                    data?.devices?.pushPermissions?.true,
                    data?.devices?.pushPermissions?.false
                  ]}
                  colors={[colors.primary, colors.warning]}
                  thickness="25"
                  tooltip="A comparison of users who have enabled push permissions versus users who have disabled push permissions."
                />
              </Col>
              <Col md="4" className="d-flex">
                {featureFlags.geofences ? (
                  <PieChart
                    title="Location permission"
                    labels={['Yes', 'No']}
                    data={[
                      data?.devices?.locationPermissions?.true,
                      data?.devices?.locationPermissions?.false
                    ]}
                    colors={[colors.primary, colors.warning]}
                    thickness="25"
                    tooltip="A comparison of users who have enabled location permissions versus users who have disabled location permissions."
                  />
                ) : (
                  <DisabledFeatureCard
                    cardTitle="Location permission"
                    feature="Geofences"
                  />
                )}
              </Col>
              <Col md="4" className="d-flex">
                <PieChart
                  title="Device type"
                  labels={data ? Object.keys(data.devices.types) : []}
                  data={data ? Object.values(data.devices.types) : []}
                  colors={[colors.primary, colors.success]}
                  thickness="25"
                  tooltip="An analysis of the type of devices that contain an active version of your app."
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="d-flex">
                <BarChart
                  title="Push permissions over time"
                  // eslint-disable-next-line
                  tooltip="An analysis of the change in user push permissions over time."
                  dataSets={[
                    {
                      label: 'Device opt in',
                      data: data?.devices?.pushPermissionsGraph?.system,
                      color: colors.warning,
                      thickness: 0.75
                    },
                    {
                      label: 'User opt in',
                      data: data?.devices?.pushPermissionsGraph?.optIn,
                      color: colors.primary,
                      thickness: 0.75
                    },
                    {
                      label: 'Combined',
                      data: data?.devices?.pushPermissionsGraph?.combined,
                      color: colors.success,
                      thickness: 1
                    }
                  ]}
                />
              </Col>
              <Col md="6" className="d-flex">
                {featureFlags.geofences ? (
                  <BarChart
                    title="Location permissions over time"
                    dataSets={[
                      {
                        label: 'Location permissions',
                        data: data?.devices?.locationPermissionsGraph,
                        color: colors.primary,
                        thickness: 0.5
                      }
                    ]}
                    tooltip="An analysis of the change in user location permissions over time."
                  />
                ) : (
                  <DisabledFeatureCard
                    cardTitle="Location permissions over time"
                    feature="Geofences"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6" className="d-flex">
                <BarChart
                  horizontal
                  title="Top OS versions"
                  dataSets={[
                    {
                      label: 'Top OS versions',
                      data: data?.devices?.osVersions?.map((v) => ({
                        x: `${v.type || ''} ${v.version}`,
                        y: v.value
                      })),
                      color: colors.primary,
                      thickness: 0.5
                    }
                  ]}
                  style={{ maxHeight: '425px' }}
                />
              </Col>
              <Col md="3" className="d-flex">
                <PieChart
                  title="Language"
                  labels={data ? Object.keys(data.devices.languages) : []}
                  data={data ? Object.values(data.devices.languages) : []}
                  colors={[
                    colors.primary,
                    colors.warning,
                    colors.success,
                    colors.danger,
                    colors.secondary,
                    colors.light
                  ]}
                  thickness="25"
                  className={pieChart}
                />
              </Col>
              <Col md="3" className="d-flex">
                <PieChart
                  title="SDK version"
                  labels={
                    data
                      ? data.devices.sdkVersions.map(
                          (v) => `${v.type || ''} ${v.version}`
                        )
                      : []
                  }
                  data={data?.devices?.sdkVersions?.map((v) => v.value)}
                  colors={[
                    colors.primary,
                    colors.warning,
                    colors.success,
                    colors.danger,
                    colors.secondary,
                    colors.tertiary,
                    colors.light
                  ]}
                  thickness="25"
                  className={pieChart}
                />
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </>
  )
}

export default withStore(DevicesDashboard)
