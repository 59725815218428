import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Col, Row, Container } from 'reactstrap'
import cn from 'classnames'
import { withStore, useStore } from '~/dataStore'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import { get } from '~/api/v2'
import { IDashboard } from './Types/Dashboard.interface'
import Stat from './UI/Stat'
import UserStat from './UI/UserStat'
import StatCardTitle from '~/pages/Dashboard/UI/StatCardTitle'
import SDKStats from '~/pages/Dashboard/UI/SDKStats'
import TotalAudienceGraph from '~/pages/Dashboard/TotalAudienceGraph'
import Select from '~/components/forms/Select'

import styles from '~/pages/Dashboard/Dashboard.scss'
import useMatchMedia from '~/hooks/useMatchMedia'

const Dashboard = () => {
  const {
    ui: { setShowSidebar },
    app: { openCurtain }
  } = useStore()
  const [data, setData] = useState<IDashboard>()
  const { appId } = useParams<{ appId: string }>()
  const {
    app: {
      appDetails: { rangeDays }
    }
  } = useStore()
  const [range, setRange] = useState<string>()
  const isLaptop = useMatchMedia('(max-width: 1520px)')

  useEffect(() => {
    get(`${appId}/dashboard_statistics/analytics`, { range }).then(
      (response) => {
        setData(response)
        openCurtain()
      }
    )
  }, [appId, openCurtain, range])

  useEffect(() => {
    if (rangeDays) {
      setRange(rangeDays[0])
    }
  }, [rangeDays])

  useEffect(() => {
    if (isLaptop) {
      setShowSidebar(false)
    }

    return () => {
      setShowSidebar(true)
    }
  }, [setShowSidebar, isLaptop])

  return (
    <>
      <Container fluid>
        <Header className="d-flex justify-content-between align-items-end mt-0 pt-3 mb-4">
          <HeaderTitle className="mb-0">Dashboard</HeaderTitle>
          {rangeDays && (
            <div className="d-flex align-items-center">
              <span className="text-nowrap pe-2">Time Period</span>
              <Select
                right
                title=""
                selectedOption={range}
                options={rangeDays.map((r) => ({
                  name: `Last ${r} days`,
                  value: r
                }))}
                onSelect={setRange}
              />
            </div>
          )}
        </Header>

        <div className="d-flex gap-4 mt-5">
          <Card className={cn('p-3', 'w-100')}>
            <UserStat
              icon="audiences"
              value={data?.audiences.active.count}
              diff={data?.audiences.active.diff}
              range={range}
              title="Active Users"
              tooltip={
                range
                  ? `Total number of users with an active app downloaded to their device whom have logged into the app in ${range} days`
                  : undefined
              }
            />
          </Card>
          <Card className={cn('p-3', 'w-100')}>
            <UserStat
              icon="new-users"
              value={data?.audiences.new.count}
              diff={data?.audiences.new.diff}
              range={range}
              title="New Active Users"
              tooltip={
                range
                  ? `Total number of users who previously never used the app, who started to use the app in ${range} days`
                  : undefined
              }
            />
          </Card>
          <Card className={cn('p-3', 'w-100')}>
            <UserStat
              icon="in-active-users"
              value={data?.audiences.inactive.count}
              diff={data?.audiences.inactive.diff}
              range={range}
              title="Inactive Users"
              tooltip={
                range
                  ? `Total number of users who had previously active and who haven't logged in ${range} days`
                  : undefined
              }
            />
          </Card>
        </div>
        <Row>
          <Col md={6}>
            <Card className={cn('p-3')}>
              <StatCardTitle
                title="Opted into Push Notifications"
                tooltip={
                  range
                    ? `Total number of users who have enabled push permissions on both their app and last active device in ${range} days`
                    : undefined
                }
              />
              <CardBody>
                <Stat
                  value={data?.audiences.pushPermission.count}
                  rate={data?.audiences.pushPermission.rate}
                  icon={
                    <div
                      className={cn(
                        `icon icon--huge icon--push-permissions`,
                        styles.statIcon
                      )}
                    />
                  }
                  diff={data?.audiences.pushPermission.diff}
                  range={range}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className={cn('p-3')}>
              <StatCardTitle
                title="Opted into Location Permissions"
                tooltip={
                  range
                    ? `Total number of users who have enabled background and foreground location permissions on their last active device in ${range} days`
                    : undefined
                }
              />
              <CardBody>
                <Stat
                  icon={
                    <div
                      className={cn(
                        `icon icon--huge icon--location-permissions`,
                        styles.statIcon,
                        styles.statIconLocation
                      )}
                    />
                  }
                  rate={data?.audiences.locationPermission.rate}
                  value={data?.audiences.locationPermission.count}
                  diff={data?.audiences.locationPermission.diff}
                  range={range}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className={cn('p-3')}>
              <StatCardTitle
                title="Total Active Users Per Month"
                tooltip="Total number of active users per month"
              />
              <CardBody>
                <TotalAudienceGraph graph={data?.audiences.activeGraphsData} />
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className={cn('p-3')}>
              <StatCardTitle
                title="Pulsate Mobile SDK Versions"
                tooltip="Users on each SDK version on last active device"
              />
              <CardBody className="px-0">
                <SDKStats tableData={data?.audiencesDevices || []} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withStore(Dashboard)
