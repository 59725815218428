import { useState } from 'react'
import cn from 'classnames'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Message } from '../../Blocks'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import Input from '~/components/forms/Input'
import JourneyNotificationSidebar from '../../JourneyNotificationSidebar'
import { withStore } from '~/dataStore'
import withDND from '~/components/DND'
import NotificationListItem from './NotificationListItem'
import { ID } from '~/common.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { NotificationType } from '~/pages/Campaign/Notification/Notification.interface'

interface IProps {
  block: Message
  journeyId: ID | undefined
  disabled: boolean
}

const SELECT_OPTIONS = [
  {
    name: MCampaignTypeToName.get(NotificationType.PUSH) || '',
    value: NotificationType.PUSH
  },
  {
    name: MCampaignTypeToName.get(NotificationType.IN_APP) || '',
    value: NotificationType.IN_APP
  },
  {
    name: MCampaignTypeToName.get(NotificationType.CARD) || '',
    value: NotificationType.CARD
  },
  {
    name: MCampaignTypeToName.get(NotificationType.EMAIL) || '',
    value: NotificationType.EMAIL
  }
]

const NotificationListItemWithDND = withDND(NotificationListItem)

const MessagePanel = ({
  block,
  journeyId,
  disabled
}: IProps): React.ReactElement => {
  const {
    name,
    setName,
    options: notifications,
    setNotification,
    saveNotification,
    replaceNotificationPosition,
    addEmptyNotification,
    removeNotification,
    selectedNotification,
    setSelectedNotification,
    rollbackNotification
  } = block

  const notificationSelectOptions = SELECT_OPTIONS.filter(
    (nOption) => !notifications.some((n) => n.type === nOption.value)
  )

  const [dragged, setDragged] = useState<{ type: ID } | undefined>()

  function setPosition(id: ID): void {
    if (dragged) {
      replaceNotificationPosition(id, dragged.type)
    }
  }

  function rollbackNotificationChanges() {
    if (!selectedNotification) {
      return
    }

    rollbackNotification(selectedNotification)
  }

  return (
    <>
      <div className="px-4">
        <Header className="mb-6">
          <HeaderTitle tag="h2">Message</HeaderTitle>
          <p className="color-subheader">
            Create one message and use it with different notification types to
            reach as many recipients as possible
          </p>
        </Header>

        <Input
          label="Message Name"
          placeholder="Enter Message Name"
          invalid={!name.isValid}
          value={name.value}
          onChange={(e) => setName(e.target.value)}
          maxLength={64}
          disabled={disabled}
          id="message-panel"
        />

        <div className="mt-6">
          <h5 className="color-body">Order of Sending Notification</h5>
          <p className="color-subheader">
            Choose what type of notification should be sent first and reach most
            of the people
          </p>
        </div>
        <div className="d-flex flex-column">
          {notifications.map((notification) => (
            <NotificationListItemWithDND
              setDragged={setDragged}
              type={notification.id}
              setPosition={setPosition}
              key={notification.id}
              position={notification.position}
              notification={notification}
              setNotification={setNotification}
              setSelectedNotification={setSelectedNotification}
              removeNotification={removeNotification}
              notificationSelectOptions={notificationSelectOptions}
              disabled={disabled}
            />
          ))}
        </div>
        {!disabled && notifications.length < SELECT_OPTIONS.length && (
          <Button
            color="primary"
            onClick={addEmptyNotification}
            className={cn('d-flex align-items-center mt-2')}>
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Another Type
          </Button>
        )}
      </div>
      <JourneyNotificationSidebar
        disabled={disabled}
        isOpen={!!selectedNotification}
        selectedNotification={selectedNotification}
        save={(notificationListItemId: ID) =>
          saveNotification(notificationListItemId, journeyId)
        }
        close={() => setSelectedNotification(null)}
        cancelCallback={rollbackNotificationChanges}
      />
    </>
  )
}

export default withStore(MessagePanel)
