import { uniqueId } from 'lodash'
import React, { PropsWithChildren, useMemo } from 'react'
import cn from 'classnames'

interface IProps {
  columnCount: number | string
  className?: string
  border?: boolean
}

export default function Columns({
  columnCount,
  children,
  className,
  border
}: PropsWithChildren<IProps>): React.ReactElement {
  const childrenWithBorder = useMemo(
    () => children.length - Math.floor(children.length / Number(columnCount)),
    [children, columnCount]
  )
  return (
    <div
      className={cn('column', [`column-${columnCount}`], className, {
        'column--custom-border': border
      })}>
      {children.flat().map((child, index) => {
        return (
          <div
            key={uniqueId()}
            className={cn('column__item', {
              'column__item--border': border && index < childrenWithBorder
            })}>
            {child}
          </div>
        )
      })}
    </div>
  )
}
