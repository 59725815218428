import { useEffect, useState } from 'react'
import cn from 'classnames'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import {
  IBlock,
  JourneyBlockType,
  NodeData,
  NodeType
} from '../Store/JourneyBuilder.interface'
import styles from './Sidebar.scss'
import EntryPanel from './EntryPanel/EntryPanel'
import { Delay, Message, Split, Start } from '../Blocks'
import MessagePanel from './MessagePanel/MessagePanel'
import DelayPanel from './DelayPanel'
import AddToSegmentPanel from './AddToSegmentPanel'
import AddToSegment from '../Blocks/AddToSegment'
import SplitPanel from './SplitPanel/SplitPanel'
import { useStore, withStore } from '~/dataStore'
import { CustomErrors, ID } from '~/common.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { isString } from '~/utils/utilities'
import { parseServerJourneyErrors } from '~/pages/Journeys/Journey.service'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal'
import RedirectPanel from './RedirectPanel'

interface IProps {
  disabled: boolean
  isOpen: boolean
  save: () => Promise<unknown> | undefined
  close: () => void
  cancelCallback: () => void
  removeBlock: () => void
  type?: NodeType | undefined
  data: NodeData | undefined
  journeyId: ID | undefined
  redirectTargets: IBlock[]
}

function RenderSidebar({
  type,
  block,
  onClick,
  journeyId,
  disabled,
  redirectTargets
}: {
  type?: NodeType
  block: IBlock
  onClick: (nodeId: ID) => void
  journeyId: ID | undefined
  disabled: boolean
  redirectTargets: IBlock[]
}): React.ReactElement | null {
  switch (type) {
    case NodeType.ENTRY:
      return <EntryPanel block={block as Start} disabled={disabled} />
    case NodeType.MESSAGE:
      return (
        <MessagePanel
          block={block as Message}
          journeyId={journeyId}
          disabled={disabled}
        />
      )
    case NodeType.ADDTOSEGMENT:
      return (
        <AddToSegmentPanel block={block as AddToSegment} disabled={disabled} />
      )
    case NodeType.DELAY:
      return <DelayPanel block={block as Delay} disabled={disabled} />
    case NodeType.SPLIT:
      return (
        <SplitPanel
          block={block as Split}
          openPanel={onClick}
          disabled={disabled}
        />
      )
    case NodeType.REDIRECT:
      return (
        <RedirectPanel
          block={block}
          disabled={disabled}
          redirectTargets={redirectTargets}
        />
      )
    default:
      return null
  }
}

function JourneyBuilderSidebar({
  isOpen,
  close: closeSidebar,
  save,
  type,
  data,
  cancelCallback,
  removeBlock,
  journeyId,
  disabled,
  redirectTargets
}: IProps): React.ReactElement {
  const {
    ui: { showModal }
  } = useStore()

  const [isLoading, setIsLoading] = useState(false)
  const [errorsOpen, setErrorsOpen] = useState(false)

  async function onSaveClick(): Promise<void> {
    try {
      setIsLoading(true)
      await save()
      closeSidebar()
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)

        if (isString((error as Error).message)) {
          if (error.body?.details) {
            const errors = parseServerJourneyErrors(error.body?.details).map(
              (err, index) => <div key={`${err}-${index}`}>{err}</div>
            )

            showNotification(<>{errors}</>, NotificationType.ERROR)
          } else {
            showNotification((error as Error).message, NotificationType.ERROR)
          }
        }
      }
      setErrorsOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancelClick(): void {
    cancelCallback()
    closeSidebar()
  }

  useEffect(() => {
    setTimeout(() => {
      setErrorsOpen(!!data?.block.errors.length)
    }, 500)
  }, [data?.block.errors?.length])

  return (
    <>
      <div
        className={cn(
          styles.sidebar,
          { [styles.sidebarActive]: isOpen },
          { [styles.sidebarMd]: type === NodeType.SPLIT }
        )}>
        {data && isOpen && (
          <div className={cn('h-100 position-relative', styles.sidebarBlock)}>
            <Button
              color=""
              className={cn(styles.sidebarClose, 'close')}
              onClick={onCancelClick}
            />
            <RenderSidebar
              disabled={disabled}
              journeyId={journeyId}
              type={type}
              block={data.block}
              redirectTargets={redirectTargets}
              onClick={(nodeId: ID) => {
                if (data.openPanel) {
                  cancelCallback()
                  data.openPanel(nodeId)
                }
              }}
            />
          </div>
        )}
        {disabled ? (
          <div
            className={cn(
              styles.sidebarBtns,
              'd-flex justify-content-end align-items-center'
            )}>
            <Button color="" onClick={closeSidebar}>
              Close
            </Button>
          </div>
        ) : (
          <div
            className={cn(
              styles.sidebarBtns,
              'd-flex justify-content-end align-items-center'
            )}>
            {type !== NodeType.ENTRY &&
              type !== NodeType.EXIT &&
              data?.block.parent?.blockType !== JourneyBlockType.START && (
                <Button
                  color="danger"
                  outline
                  className="me-auto"
                  onClick={() => showModal('removeBlockConfirm')}>
                  <FontAwesomeIcon className="me-2" icon={faTrash} />
                  Delete
                </Button>
              )}
            <Button color="" onClick={onCancelClick} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              id="save-block-btn"
              color="body"
              disabled={!data?.block.isValid || isLoading}
              className="btn--hover px-4"
              onClick={onSaveClick}>
              Save
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="xs"
                  className="ms-1"
                />
              )}
            </Button>
            {!data?.block.isValid && data?.block.errors && (
              <BuilderErrors
                targetId="save-block-btn"
                messages={data.block.errors}
                close={() => setErrorsOpen(false)}
                isOpen={errorsOpen}
              />
            )}
          </div>
        )}
      </div>
      <Modal
        id="removeBlockConfirm"
        size="md"
        renderHeader={() => 'Delete block'}
        render={(closeModal: () => void) => (
          <ConfirmationModal
            onClose={closeModal}
            onSubmit={() => {
              removeBlock()
              closeSidebar()
              closeModal()
            }}
            okText="Delete">
            Are you sure you want to delete this block from the journey?
          </ConfirmationModal>
        )}
      />
    </>
  )
}

export default withStore(JourneyBuilderSidebar)
