import { CSSProperties, ReactElement, useState } from 'react'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import Step1 from './Step1'
import Step2 from './Step2'
import { registerNewAccount } from '~/api/admins'
import styles from './NewAccountRegistration.scss'
import { showGeneralError } from '~/utils/validations'
import {
  STEP1_FORM_FIELDS,
  TStep1FormFields,
  TStep2FormFields
} from './NewAccountRegistration.interface'
import routes from '~/routes'

const NewAccountRegistration = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [step1FormState, setStep1FormState] = useState<TStep1FormFields>({
    passwordConfirmation: '',
    password: '',
    email: '',
    username: '',
    name: ''
  })
  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState()
  const history = useHistory()

  const saveFormState = (data: TStep1FormFields) => {
    setStep1FormState(data)
    if (step === 1) {
      setStep(2)
    }
  }

  const saveForm = async (data: TStep2FormFields) => {
    try {
      setIsLoading(true)
      await registerNewAccount({ ...step1FormState, ...data })
      history.push('/')
    } catch (error) {
      if (error.body.errors) {
        setErrors(error.body.errors)
        if (
          Object.keys(error.body.errors).some((value) =>
            STEP1_FORM_FIELDS.includes(value)
          )
        ) {
          setStep(1)
        }
      } else {
        showGeneralError()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const calculateTranslation = (): CSSProperties => {
    if (step === 1) {
      return {}
    }

    return {
      transform: `translateX(-${(step - 1) * 100}%)`
    }
  }

  return (
    <>
      <h2 className="mb-0">New Account Registration</h2>
      <div
        className={cn('mt-4 pt-2', styles.formWrapper, {
          [styles.formWrapperStep1Active]: step === 1,
          [styles.formWrapperStep2Active]: step === 2
        })}>
        <div
          className={cn(styles.step, {
            [styles.stepActive]: step === 1
          })}
          style={calculateTranslation()}>
          <Step1
            isActive={step === 1}
            serverSideErrors={errors}
            saveFormState={saveFormState}
          />
        </div>

        <div
          className={cn(styles.step, {
            [styles.stepActive]: step === 2
          })}
          style={calculateTranslation()}>
          <Step2
            isActive={step === 2}
            serverSideErrors={errors}
            saveForm={saveForm}
            goToPreviousStep={() => setStep(1)}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  )
}

export default NewAccountRegistration
