import React, { useEffect, useRef } from 'react'
import { Button, Label, ModalBody, ModalFooter } from 'reactstrap'
import cn from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import Modal from '~/components/modals/Modal/Modal'
import Input from '~/components/forms/Input'
import helpText from './components/helpText.svg'
import helpTextMemberId from './components/helpTextMemberId.svg'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'
import { createSegmentFromCSVFile } from '~/api/segments'
import { useStore } from '~/dataStore'
import { ERRORS_DICT, FormValues } from './CreateSegmentFromCSVModal.interface'
import { ServerStateKeysEnum } from '../SegmentsList/useSegmentsList'
import CustomTagInput from './components/CustomTagInput'

const ModalContent = ({
  closeModal,
  segmentName,
  customTag
}: {
  closeModal: () => void
  segmentName?: string
  customTag?: string
}) => {
  const queryClient = useQueryClient()
  const {
    ui: { showModal, hideModal },
    app: {
      appDetails: {
        featureFlags: { coreMemberIdUpload }
      },
      currentApp: { id: appId }
    }
  } = useStore()
  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>()
  const modalBodyRef = useRef<HTMLDivElement>(null)

  const onSubmit = async (data: FormValues) => {
    const formData = new FormData()
    if (data.file instanceof FileList && data.file.length > 0) {
      formData.append('file', data.file[0])
    }
    formData.append('segment_name', data.segmentName || '')
    formData.append('custom_tag', data.customTag || '')
    try {
      await createSegmentFromCSVFile(appId, formData)
      showNotification(
        `Segment ${data.segmentName} has been successfully created.`,
        NotificationType.SUCCESS
      )
      hideModal('createSegmentFromCSVModal')
      queryClient.invalidateQueries([ServerStateKeysEnum.Segments])
    } catch (error: Error & { body: any }) {
      if (error.body?.errors) {
        if ('identifiers' in error.body.errors) {
          const { identifiers } = error.body.errors
          if (Array.isArray(identifiers)) {
            hideModal('createSegmentFromCSVModal')
            showModal('segmentCSVErrorsModal', {
              identifiers,
              segmentName: data.segmentName,
              customTag: data.customTag,
              csvDownloadLink: error.body.errors.link
            })
          } else {
            showNotification(identifiers, NotificationType.ERROR)
            setError('file', {
              type: 'server',
              message: identifiers
            })
          }
        } else {
          getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach(
            (er) => setError(er.name, { type: 'server', message: er.message })
          )
        }
      } else {
        showGeneralError()
      }
    }
  }

  useEffect(() => {
    reset({
      segmentName,
      customTag
    })
  }, [segmentName, customTag])

  return (
    <div ref={modalBodyRef}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="my-3 px-0">
          <Input
            name="segmentName"
            placeholder="Enter Segment Name"
            register={register}
            className={cn('mb-3', {
              'form-group--error': errors.segmentName
            })}
            errorTooltip={errors?.segmentName?.message}
            validation={{
              required: {
                value: true,
                message: 'Segment Name is missing.'
              }
            }}
            label="Segment Name"
          />
          <Controller
            control={control}
            name="customTag"
            rules={{
              required: {
                value: true,
                message: 'Custom Data Tag is missing.'
              }
            }}
            render={({ field: { onChange }, formState: { errors } }) => (
              <CustomTagInput
                defaultValue={customTag}
                error={errors?.customTag?.message}
                hintTargetRef={modalBodyRef}
                onChange={onChange}
              />
            )}
          />

          <Label className="fw-medium form-label mt-4">Upload .CSV</Label>
          <Input
            register={register}
            name="file"
            className={cn('mb-3', {
              'form-group--error': errors.file
            })}
            type="file"
            accept=".csv"
            validation={{
              required: {
                value: true,
                message: '.CSV file is missing.'
              }
            }}
            errorTooltip={errors?.file?.message}
            tooltip=""
          />
          <img
            src={coreMemberIdUpload ? helpTextMemberId : helpText}
            className="mt-4 pt-3 w-100"
            alt="Recreate this column in your .CSV file"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="primary" disabled={isSubmitting}>
            Save Segment
          </Button>
        </ModalFooter>
      </form>
    </div>
  )
}

const CreateSegmentFromCSVModal = (): React.ReactElement => {
  return (
    <>
      <Modal
        id="createSegmentFromCSVModal"
        renderHeader={() => 'Create New Segment'}
        render={(
          close: () => void,
          params: { segmentName?: string; customTag?: string }
        ) => (
          <ModalContent
            closeModal={close}
            segmentName={params?.segmentName}
            customTag={params?.customTag}
          />
        )}
      />
    </>
  )
}

export default CreateSegmentFromCSVModal
