import { get, put, destroy, post } from '~/api/v2'
import { ICurrentAdminDTO } from '~/dataStore/App.interface'
import { getRequest, postRequest } from '~/api/requests'
import { ID } from '~/common.interface'
import {
  IAdmin,
  AdminsDTO,
  AdminFormFieldValues,
  SecurityFormFieldValues
} from '~/pages/AccountSettings/AccountSettings.interface'
import { IFormFields, ISignInDTO } from '~/pages/Auth/Auth.interface'

export function updateAccessTokens(appId: string): Promise<{
  message: string
  adminAccessToken: string
}> {
  return put(`${appId}/admins/update_current_admin_access_tokens`)
}

export function fetchAdmins(
  appId: string,
  params?: { perPage: number; page: number }
): Promise<AdminsDTO> {
  return get(`${appId}/admins`, params)
}

export function fetchCurrentAdmin(): Promise<ICurrentAdminDTO> {
  return getRequest('/admins/current_admin')
}

export function updateCurrentAdmin(
  appId: ID,
  data: { jobTitle: string; name: string; reset: string; avatarS3Url?: string }
): Promise<void> {
  return put(`${appId}/admins/update_current_admin`, data)
}

export function updateCurrentAdminPassword(
  appId: ID,
  data: SecurityFormFieldValues
): Promise<void> {
  return put(`${appId}/admins/update_current_admin_password`, data)
}

export function updateAdminPrivileges(
  appId: ID,
  adminId: ID,
  data: Pick<AdminFormFieldValues, 'managedAppId' | 'role' | 'allowedActions'>
): Promise<{ admin: IAdmin; message: string }> {
  return put(`${appId}/admins/${adminId}/update_privileges`, data)
}

export function removeAdmin(appId: ID, adminId: ID): Promise<void> {
  return destroy(`${appId}/admins/${adminId}`)
}

export function addAdmin(
  appId: ID,
  data: AdminFormFieldValues
): Promise<{ admin: IAdmin; message: string }> {
  return post(`${appId}/admins/invite_admin`, data)
}

export function registerNewAccount(
  data: {
    name: string
    username: string
    email?: string
    password: string
    passwordConfirmation: string
    companyName?: string
    appName?: string
    activationCode?: string
    inviteToken?: string
  },
  signal?: AbortSignal
): Promise<{ data: IAdmin; path: string; message: string }> {
  return postRequest(`/admins/api/v2/register`, data, 'POST', signal)
}

export function signInAdmin(
  data: IFormFields & { 'g-recaptcha-response': string },
  signal?: AbortSignal
): Promise<ISignInDTO> {
  return postRequest(`/admins/api/v2/login`, data, 'POST', signal)
}
