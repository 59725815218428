import React, { PropsWithChildren, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button, ModalBody, ModalFooter } from 'reactstrap'

interface Props {
  onClose: () => void
  onSubmit?: (() => void) | undefined
  cancelText?: string | React.ReactNode
  okText?: string | React.ReactNode
}

const ConfirmationModal = ({
  onClose,
  onSubmit,
  cancelText = 'Cancel',
  okText,
  children
}: PropsWithChildren<Props>): React.ReactElement => {
  const [submitting, setSubmitting] = useState(false)

  async function handleSubmit() {
    try {
      setSubmitting(true)
      await onSubmit?.()
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <ModalBody className="px-0">{children}</ModalBody>
      <ModalFooter>
        <Button
          color=""
          onClick={onClose}
          className="btn--hover"
          disabled={submitting}>
          {cancelText}
        </Button>
        {onSubmit && (
          <Button
            color="primary"
            disabled={submitting}
            className="px-4 btn--hover"
            onClick={handleSubmit}>
            {submitting && (
              <FontAwesomeIcon
                className="me-1"
                icon={faSpinner}
                spin
                size="xs"
              />
            )}
            {okText}
          </Button>
        )}
      </ModalFooter>
    </>
  )
}

export default ConfirmationModal
